import { useContext } from "react"
import {
  ActionIcon,
  AppShell,
  Burger,
  Group,
  Image,
  rem,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import logo from "@assets/logo_square.png"
import DarkToggle from "@components/DarkToggle"
import LoadingStatus from "@components/LoadingStatus"
import ProjectIcon from "@components/ProjectIcon"
import { ProjectContext } from "@lib/projectProvider"
import { NewProject } from "../../routes/project/NewProject"
import { ClientSwitcher } from "./TeamSwitcher"

import {
  IconChecklist,
  IconLayoutGridAdd,
  IconLifebuoy,
  IconLoader3,
  IconPlus,
  IconSettings,
} from "@tabler/icons-react"
import _sortBy from "lodash/sortBy"
import { useLocation, useNavigate } from "react-router-dom"

import { LayoutNavbarMenuItem } from "./Layout.Navbar.MenuItem"
import classes from "./Layout.module.css"
import NavbarCollapseToggle from "./NavbarCollapseToggle"

export const LayoutNavbar = ({ setShowNavbar, showNavbar }) => {
  const theme = useMantineTheme()
  const [collapsed] = useLocalStorage({
    defaultValue: false,
    key: "navbar-collapsed",
  })
  const [worksheets] = useLocalStorage({ defaultValue: [], key: "worksheets" })
  const { loadingProjects, projects } = useContext(ProjectContext)
  const navigate = useNavigate()
  const location = useLocation()

  const openNewProjectModal = () => {
    modals.open({
      size: "lg",
      title: "Add another domain",
      children: <NewProject />,
      modalId: "new-project",
    })
  }

  return (
    <AppShell.Navbar>
      <Stack w={collapsed ? "45px" : "280px"} className={"navbarInner"}>
        <Stack gap={"5px"} opacity={0.95}>
          <Group hiddenFrom={"xs"} justify={"space-between"}>
            <Burger
              color={theme.colors.gray[6]}
              mr="xl"
              onClick={() => setShowNavbar(!showNavbar)}
              opened={showNavbar}
              size="md"
            />
            <Image className={classes.logo} src={logo} w={40} />
          </Group>

          <Group
            p={collapsed ? "2px" : "7px"}
            visibleFrom={"xs"}
            justify={"space-between"}
          >
            <Group>
              {!collapsed && (
                <Text size={"1.5rem"} weight={200} ml={"sm"}>
                  Ogify
                </Text>
              )}
            </Group>
            <ClientSwitcher />
          </Group>

          <LayoutNavbarMenuItem
            active={location.pathname === "/"}
            collapsed={collapsed}
            icon={<IconLayoutGridAdd size="1.5rem" stroke={1.5} />}
            onClick={() => {
              setShowNavbar(false)
              navigate("/")
            }}
          >
            <Group justify={"space-between"}>
              <div>Dashboard</div>
              {!collapsed && (
                <ActionIcon variant={"outline"}>
                  <IconPlus onClick={openNewProjectModal} stroke={"1px"} />
                </ActionIcon>
              )}
            </Group>
          </LayoutNavbarMenuItem>

          {collapsed && (
            <LayoutNavbarMenuItem
              collapsed={collapsed}
              icon={<IconPlus stroke={1} />}
              onClick={openNewProjectModal}
            >
              Create new project
            </LayoutNavbarMenuItem>
          )}
        </Stack>

        <AppShell.Section component={ScrollArea} grow mb={"xs"} mt={"xs"}>
          {!projects && loadingProjects && (
            <Group pl={"4px"}>
              <LoadingStatus label={"Loading"} stroke={1} />
            </Group>
          )}

          {projects && (
            <Stack gap={"4px"}>
              {projects &&
                _sortBy(projects, "projectName").map((p) => (
                  <Stack gap={"2px"} key={`${p.projectID}_box`}>
                    <LayoutNavbarMenuItem
                      active={location.pathname === `/projects/${p.projectID}`}
                      collapsed={collapsed}
                      description={p.sitemap}
                      icon={
                        (p.status &&
                          p.status.projectID === p.projectID &&
                          p.status.processing) ||
                        p.status?.processing ? (
                          <IconLoader3 className={"spin"} />
                        ) : (
                          <ProjectIcon project={p} />
                        )
                      }
                      onClick={() => {
                        navigate(`/projects/${p.projectID}`)
                        setShowNavbar(false)
                      }}
                    >
                      {p.projectName}
                    </LayoutNavbarMenuItem>

                    {worksheets
                      .filter((ws) => ws.projectID === p.projectID)
                      .map((ws, i) => (
                        <LayoutNavbarMenuItem
                          active={location.pathname === `/worksheets/${ws.id}/`}
                          collapsed={collapsed}
                          description={`${ws.sites.length} sites`}
                          icon={<IconChecklist size="1.5rem" stroke={1.5} />}
                          key={"m" + i}
                          onClick={() => {
                            navigate(`/worksheets/${ws.id}`)
                            setShowNavbar(false)
                          }}
                          variant={"subtle"}
                        >
                          <> - {ws.name}</>
                        </LayoutNavbarMenuItem>
                      ))}
                  </Stack>
                ))}
            </Stack>
          )}
        </AppShell.Section>

        <AppShell.Section>
          <Stack gap={"5px"}>
            <LayoutNavbarMenuItem
              active={location.pathname.split("/")[1] === "settings"}
              collapsed={collapsed}
              icon={<IconSettings stroke={1} />}
              onClick={() => {
                navigate("/settings")
                setShowNavbar(false)
              }}
            >
              Settings
            </LayoutNavbarMenuItem>

            <LayoutNavbarMenuItem
              active={location.pathname === "/help"}
              collapsed={collapsed}
              icon={<IconLifebuoy stroke={1} />}
              onClick={() => {
                navigate("/help")
                setShowNavbar(false)
              }}
            >
              Help
            </LayoutNavbarMenuItem>
          </Stack>

          <Group gap={"2px"} justify={"space-between"} mt={"10px"}>
            <DarkToggle />
            <NavbarCollapseToggle hiddenFrom="sm" />
          </Group>
        </AppShell.Section>
      </Stack>
    </AppShell.Navbar>
  )
}
