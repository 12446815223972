import { useLocalStorage } from "@mantine/hooks"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { getClientSession } from "./session.js"
import { useQuery } from "./useQueryHook.js"

const emailMatcher = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
const apiURL = import.meta.env.VITE_API_URL
const returnURL = window.location.protocol + "//" + window.location.host //import.meta.env.VITE_RETURN_URL;
export const useOggy = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const query = useQuery()
  const [locked, setLocked] = useState(false)

  const [token, setToken, clearToken] = useLocalStorage({
    key: "ogify-token",
    defaultValue: JSON.parse(localStorage.getItem("ogify-token")) || false,
  })

  const [session, setSession, clearSession] = useLocalStorage({
    defaultValue: null,
    key: "user",
  })

  const [lastResend, setLastResend] = useLocalStorage({
    defaultValue: null,
    key: "lastResend",
  })

  useEffect(() => {
    if (lastResend && lastResend < new Date().getTime() - 1000 * 60 * 5) {
      setLastResend(null)
    }
  }, [setLastResend, lastResend])

  useEffect(() => {
    if (session) {
      const client = getClientSession()
      if (new Date(Date.parse(client.meta.validUntil)) < new Date()) {
        setLocked(true)
      }
    }
  }, [session])

  const getApiAdapter = (config = { headers: {} }) => {
    config.headers["x-ogify-token"] = token
    config.headers["Content-Type"] = "application/json"

    const instance = axios.create(config)
    instance.interceptors.request.use((config) => {
      if (config.method === "get") {
        config.headers["Content-Encoding"] = "gzip, deflate"
      }
      return config
    })

    instance.interceptors.response.use(
      (response) => {
        if (
          response.headers["x-ogify-token"] &&
          response.headers["x-ogify-token"] !== token
        ) {
          setToken(response.headers["x-ogify-token"])
        }
        return response
      },
      (error) => {
        if (error.response.status === 401) {
          clearSession()
          clearToken()
          navigate("/auth/login")
        }

        if (error.response && error.response.data) {
          return Promise.reject(error.response.data)
        }
        return Promise.reject(error.message)
      }
    )
    return instance
  }

  const signIn = async (data) => {
    setLoading(true)
    setError(null)

    const url = `${apiURL}/auth/login`

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    try {
      return axios
        .post(url, data.values, config)
        .then((res) => {
          if (res.headers["x-ogify-token"] !== undefined) {
            setToken(res.headers["x-ogify-token"])
            setSession(res.data)

            navigate(
              query.get("r") && query.get("r") !== window.location.pathname
                ? query.get("r")
                : "/"
            )
          } else {
            setError(res.data.msg)
          }
          return res
        })
        .catch((err) => {
          return err
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (e) {
      console.log(e)
    }
  }

  const loadPasswordRequest = async (uuid) => {
    return axios.get(`${apiURL}/auth/resetpassword/${uuid}`)
  }

  const resetPassword = async (formValues) => {
    setLoading(true)
    return axios
      .put(`${apiURL}/auth/resetpassword`, formValues)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        if (err) {
          setError(err.response.data)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const requestPasswordReset = (values) => {
    setError(null)
    if (!values.email) {
      setError("Please provide the email address you signed up with")
      return false
    }
    if (!String(values.email).toLowerCase().match(emailMatcher)) {
      setError("Huh?! This email address doesnt look valid")
      return false
    }

    setLoading(true)
    return axios
      .post(apiURL + "/auth/resetpassword", {
        email: values.email,
        return_url: returnURL + "/auth/resetpassword/#UUID#",
      })
      .then((response) => {
        if (!response.data.success) {
          if (response.data.msg === "user_notfound") {
            setError("Could not reset password. Please try again later.")
            return "Could not reset password. Please try again later."
          }
        }
        return response.data
      })
      .catch((error) => {
        console.log(error)

        if (error.msg === "user_notfound") {
          setError("Could not reset password. Please try again later.")
          return "Could not reset password. Please try again later."
        }

        setError("Unknown error")
        return "Unknown error"
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const signUp = (formData) => {
    setError(null)
    setLoading(true)

    if (!String(formData.email).toLowerCase().match(emailMatcher)) {
      setError({
        msg: "This email address doesnt look valid",
        type: "invalid_email",
      })
      setLoading(false)
      return
    }

    // add return URL
    formData.return_url = returnURL + "/auth/verify/#UUID#"
    axios
      .post(`${apiURL}/auth/registration`, formData)
      .then((response) => {
        if (!response.data.success) {
          ////  IGNORE WE DON'T GIVE AWAY IF A USER EXISTS OR NOT
          switch (response.data.msg) {
            case "user_exist":
              setError({
                msg: "An account with this email address already exists",
                type: "user_exist",
              })
              break
            default:
              setError({ msg: "Sorry, there was an error.", type: "unknown" })
          }
          setLoading(false)
          return
        }

        const token = response.headers["x-ogify-token"]
        if (!token) {
          navigate("/auth/login?r=/firstRun")
          return
        }

        setError(false)
        setSession(response.data)
        setToken(token)

        navigate("/firstRun")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const verifyAccount = (uuid) => {
    if (uuid) {
      setLoading(true)
      setError(false)
      return getApiAdapter()
        .put(`${apiURL}/auth/verification`, { uuid: uuid })
        .then((response) => {
          if (!response.data.success) {
            const error = { code: response.data.msg, msg: "" }
            setError(error)
            return error
          }

          if (response.data.success) {
            setSession({ ...session, me: { ...response.data } })
          }

          return response.data
        })
        .catch((err) => {
          const error = { code: err.msg, msg: "" }
          setError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const [success, setSuccess] = useState(false) // [success, setSuccess
  const resendVerificationEmail = () => {
    setLoading(true)
    setSuccess(false)
    setError(false)
    return getApiAdapter()
      .post(`${apiURL}/auth/verification`, {
        return_url: returnURL + "/auth/verify/#UUID#",
      })
      .then((response) => {
        if (!response.data.success) {
          const error = { code: response.data.msg, msg: "" }
          setError(error)
          return error
        }
        setLastResend(new Date().getTime())
        setSuccess(true)
        return response.data
      })
      .catch((error) => {
        setError(error)
        console.error(error)
        return error
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const signOut = () => {
    clearToken()
    clearSession()
    // navigate("/auth/login");
  }

  const countOggyTabs = async () => {
    const broadcastChannel = new BroadcastChannel("OgifyAppV1")

    let numTabs = 0
    broadcastChannel.postMessage("callingOtherTabs")
    broadcastChannel.onmessage = () => {
      numTabs++
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(numTabs)
      }, 100)
    })
  }

  return {
    countOggyTabs,
    error,
    getApiAdapter,
    lastResend,
    loadPasswordRequest,
    loading,
    locked,
    requestPasswordReset,
    resendVerificationEmail,
    resetPassword,
    signIn,
    signOut,
    signUp,
    success,
    token,
    verifyAccount,
  }
}
